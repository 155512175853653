<template>
  <div>
    <Breadcrumb :model="items"/>
    <div class="p-mt-3 p-p-3" style="background: #ffffff;border: 1px solid #dee2e6">

      <DataTable
          :value="list"
          :auto-layout="true"
          :paginator="true" :rows="10"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5,10,25]"
          currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录">
        <template #header>
          <div class="p-d-flex p-mb-2">
            <InputText class="p-mr-2" v-model="name" placeholder="标题关键字搜索"/>
            <Calendar @clear-click="getData" onfocus=this.blur() inputId="calendar" v-model="time" placeholder="日期" dateFormat="yy-mm-dd"
                      showButtonBar="true"/>
            <Dropdown class="p-ml-2" v-model="state" :options="states" optionLabel="name"
                      placeholder="状态" option-value="code" :show-clear="true"/>
            <Button class="p-ml-2" @click="getData">查询</Button>
          </div>
        </template>
        <Column field="name" header="活动标题"></Column>
        <Column field="addr" header="地址"></Column>
        <Column field="industry" header="行业"></Column>
        <Column field="time" header="时间">
          <template #body="a">
            {{ new Date(a.data.time).toLocaleString() }}
          </template>
        </Column>
        <Column field="attend" header="状态">
          <template #body="a">
            {{ a.data.attend == 1 ? '已同意' : '已拒绝' }}
          </template>
        </Column>
        <Column header="操作">
          <template #body="a">
            <div v-if="a.data.attend==0">
              <Button class="p-button-sm p-m-2" @click="updateState(a.data.id,1)">同意参会</Button>
              <Button class="p-button-sm p-m-2" @click="updateState(a.data.id,2)">拒绝参会</Button>
            </div>
          </template>
        </Column>
      </DataTable>


    </div>
  </div>
  <Toast/>
</template>

<script>
export default {
  name: "events",
  data() {
    return {
      state:null,
      name: null,
      time: null,
      states:[
        {name:'待响应',code:0},
        {name:'同意',code:1},
        {name:'拒绝',code:2},
      ],
      items: [
        {label: '活动组织管理', to: '/'},
        {label: '活动安排'}
      ],
      publishTime: null,
      eventSts: null,
      list: [],
      activity: {},

    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      console.log(this.time)
      this.$http.get('/event/getEventAboutMe', {
        params: {
          text: this.name,
          time:this.time,
          state:this.state
        }
      }).then((res) => {
        this.list = res.data
      })
    },
    updateState(id, state) {
      this.$http.post('/event/attendEvent?id=' + id + '&state=' + state).then(() => {
        this.getData()
        this.$toast.add({severity: 'success', summary: '成功', life: 3000})
      })
    },
    goto() {
      this.$router.push('/eventDetail')
    },


  }
}
</script>

<style scoped>

</style>